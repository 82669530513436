import './bootstrap';
import AOS from 'aos';
import 'es6-promise/auto';
import 'moment';
import Swal from 'sweetalert2';
import AppLoader from './AppLoader';
import dayjs from 'dayjs';
import './landingPage/apps/landingPage';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
window.dayjs = dayjs;

window.swal = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-default',
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    reverseButtons: true,
});

window.toaster = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

window.$app = new AppLoader();

// https://developers.google.com/recaptcha/docs/loading
// How this code snippet works:
// This logic overwrites the default behavior of `grecaptcha.ready()` to
// ensure that it can be safely called at any time. When `grecaptcha.ready()`
// is called before reCAPTCHA is loaded, the callback function that is passed
// by `grecaptcha.ready()` is enqueued for execution after reCAPTCHA is
// loaded.
if (typeof grecaptcha === 'undefined') {
    window.grecaptcha = {};
}
window.grecaptcha.ready = function(cb) {
    if (typeof grecaptcha === 'undefined') {
        // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
        // configuration. By default, any functions listed in its 'fns' property
        // are automatically executed when reCAPTCHA loads.
        const c = '___grecaptcha_cfg';
        window[c] = window[c] || {};
        (window[c].fns = window[c].fns || []).push(cb);
    } else {
        cb();
    }
};

$(() => {
    AOS.init({
        //
    });
});
