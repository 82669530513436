export default class AppLoader {

    /**
     * Register all possible apps this loader can initialize.
     *
     * @param apps
     * @return {AppLoader}
     */
    register(apps) {
        this.apps = apps;

        return this;
    }

    /**
     * Set the identifier of the app the loader should initialize
     * along with any app data that may be needed.
     *
     * @param identifier
     * @param data
     * @return {AppLoader}
     */
    load(identifier, data) {
        this.identifier = identifier;
        this.data = data;

        return this;
    }

    /**
     * Run the initializer for the loaded app.
     *
     * @returns {*}
     */
    createApp() {
        let identifier = this.identifier;
        if (!identifier || !this.apps[identifier]) {
            identifier = 'minimal';
        }
        return this.apps[identifier](this.data);
    }
}
